<div class="modal-body">
  <!-- Profile Section -->
  <div class="profile-section">
    <h1 class="avatar">{{ userProfileDetails?.initials }}</h1>
    <div class="profile-info">
      <h3 class="profile-name">
        {{ userProfileDetails.name }}
        {{ userProfileDetails.surname}}
      </h3>
      <p class="profile-email">{{ profileForm.get('email')?.value }}</p>
    </div>
  </div>

  <!-- Form Fields Section -->
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="input-container">
      <label for="name" class="input-label">{{
        'form.name' | translate
      }}</label>
      <input [ngClass]="{
        'spaui-error-input':
        nameControl?.invalid && nameControl?.touched
      }"
        type="text"
        id="name"
        formControlName="name"
        class="input-field"
        placeholder="Name"
      />
      <p *ngIf="nameControl?.invalid && nameControl?.touched" class="error-message">
        {{ getErrorMessage('name') }}
      </p>
    </div>

    <div class="input-container">
      <label for="surname" class="input-label">{{
        'form.surname' | translate
      }}</label>
      <input [ngClass]="{
        'spaui-error-input':
        surnameControl?.invalid && surnameControl?.touched
      }"
        type="text"
        id="surname"
        formControlName="surname"
        class="input-field"
        placeholder="Surname"
      />
      <p *ngIf="surnameControl?.invalid && surnameControl?.touched" class="error-message">
        {{ getErrorMessage('surname') }}
      </p>
    </div>

    <div class="input-container">
      <label for="email" class="input-label">{{
        'form.email' | translate
      }}</label>
      <input
        type="email"
        id="email"
        formControlName="email"
        class="input-field"
        placeholder="youremail@example.com"
      />
    </div>

    <div class="input-container">
      <label for="language" class="input-label">{{
        'form.language' | translate
      }}</label>
      <select id="language" formControlName="language" class="input-field">
        <option *ngFor="let language of languages" [value]="language.key">
          {{ language.name }}
        </option>
      </select>
    </div>
  </form>
</div>
<div class="footer">
  <spaui-button
    [text]="'form.cancel' | translate"
    [color]="'outline'"
    [size]="'small'"
    class="cancel"
    (click)="onCancel()"
  ></spaui-button>
  <spaui-button
    type="submit"
    [text]="'form.save' | translate"
    [color]="'primary'"
    [size]="'small'"
    (click)="onSubmit()"
  ></spaui-button>
</div>
