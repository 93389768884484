import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.scss'
})
export class ChangePasswordDialogComponent {
  idsURL = environment.idsConfig.authority;
  URLChangePassword!: SafeResourceUrl;

  constructor(private sanitizer:DomSanitizer){
    const url = `${this.idsURL}/Manage/ChangePassword?embedded=true`
    this.URLChangePassword = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
