<div class="panel-wrapper">
  <div class="panel-container">
    <spaui-collapsible
      direction="horizontal"
      [(expanded)]="isPanelExpanded"
      [id]="'CollapsePlaylist'"
      pageDirection="left"
      [header]="true"
      titleHeader="{{ 'texts.notifications' | translate }}"
      titleHeaderIcon="./assets/images/icons/notifications_filled.svg"
      [showTextIcon]="true"
      (expandedChange)="isExpanded = $event"
      (panelClicked)="onPanelClicked($event)"
    >
      @if (isPanelExpanded) {
      <div class="panel-content">
        <spaui-tab-group>
          <!-- <spaui-tab tabTitle="Messages">
            <p>Matches</p>
          </spaui-tab> -->
          <spaui-tab tabTitle=" {{ 'form.download' | translate }}" [showBadge]="downloadUnreadCount > 0" [badgeText]="downloadUnreadCount">
            @for (download of downloads; track $index) { @switch (download.type)
            { @case ('preparing') {
            <!-- * Download Preparing -->
            <div class="download-item unread">
              <div class="download-item__header">
                <div class="file-icon">
                  <img src="./assets/images/icons/video_file.svg" alt="File" />
                </div>
                <div class="file-info">
                  <p class="file-title">SPA_”Name of playlist”_”Timestamp”</p>
                  <span class="file-time">Yesterday</span>

                  <div class="download-processing">
                    <p class="download-processing__info">
                      {{ 'texts.downloadPreparing' | translate }}
                    </p>
                    <p class="download-processing__info">
                      {{ 'texts.downloadFileEmail' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            } @case ('success') {
            <!-- * Download Success -->
            <div class="download-item">
              <div class="download-item__header">
                <div class="file-icon">
                  <img src="./assets/images/icons/folder_zip.svg" alt="File" />
                </div>
                <div class="file-info">
                  <p class="file-title">SPA_”Name of playlist”_”Timestamp”</p>
                  <span class="file-time">Yesterday</span>

                  <div class="download-success">
                    <div class="alert alert__success">
                      <div class="alert-icon">
                        <img
                          src="./assets/images/icons/download.svg"
                          alt="Download"
                        />
                      </div>
                      <div class="alert-message">
                        PA_Shoots from RMA_191224_1402X_XX_XXX_X.zip
                      </div>
                    </div>
                    <p class="download-success__info">
                      {{ 'texts.downloadAvailable' | translate : { days } }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            } @case ('error') {
            <!-- * Download Error -->
            <div class="download-item">
              <div class="download-item__header">
                <div class="file-icon">
                  <img src="./assets/images/icons/video_file.svg" alt="File" />
                </div>
                <div class="file-info">
                  <p class="file-title">SPA_”Name of playlist”_”Timestamp”</p>
                  <span class="file-time">Yesterday</span>

                  <div class="download-error">
                    <div class="alert alert__error">
                      <div class="alert-icon">
                        <img
                          src="./assets/images/icons/error.svg"
                          alt="Download"
                        />
                      </div>
                      <div class="alert-message">
                        PA_Shoots from RMA_191224_1402X_XX_XXX_X.zip
                      </div>
                    </div>
                    <p class="download-error__info">
                      {{ 'errors.errorOccurred' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            } @case ('expired') {
            <!-- * Download Expired -->
            <div class="download-item">
              <div class="download-item__header">
                <div class="file-icon">
                  <img src="./assets/images/icons/video_file.svg" alt="File" />
                </div>
                <div class="file-info">
                  <p class="file-title">SPA_”Name of playlist”_”Timestamp”</p>
                  <span class="file-time">Yesterday</span>

                  <div class="download-expired">
                    <p class="download-expired__info">
                      {{ 'texts.downloadExpired' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            } } } @empty {
            <div class="empty-container">
              <spaui-empty-state
                [imageSource]="
                  './assets/images/placeholders/notification-download-empty.svg'
                "
                [mainHeading]="'texts.noDownloads' | translate"
                [subHeading]="'texts.noDownloadYet' | translate"
              ></spaui-empty-state>
            </div>
            }
          </spaui-tab>
        </spaui-tab-group>
      </div>
      <div class="panel-footer">
        <spaui-button
          class="spaui-confirm-dialog__footer-button spaui-confirm-dialog__footer-button--accept"
          [text]="'form.accept' | translate"
          [color]="'primary'"
          (click)="onAccept()"
        ></spaui-button>
      </div>
      }
    </spaui-collapsible>
  </div>

  <div class="overlay-black"></div>
</div>
