// nav.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav.component';
import { MatDividerModule } from '@angular/material/divider';
import { MenuModule, ButtonModule } from '@sportiananalysis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { ToastModule } from "src/app/components/toast/toast.module";
import { UserProfileDialogModule } from 'src/app/shared/dialogs/user-profile-dialog/user-profile-dialog.module';
import { ChangePasswordDialogModule } from 'src/app/shared/dialogs/change-password-dialog/change-password-dialog.module';
import { RouterModule } from '@angular/router';
import { CollapsibleModule } from 'src/app/components/collapsible/collapsible.module';
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component';


@NgModule({
  declarations: [
    NavComponent
  ],
  imports: [
    ButtonModule,
    CommonModule,
    MatDividerModule,
    MenuModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserProfileDialogModule,
    MaterialModule,
    ToastModule,
    ChangePasswordDialogModule,
    RouterModule,
    CollapsibleModule,
    NotificationPanelComponent
  ],
  exports: [
    NavComponent
  ]
})
export class NavModule { }
