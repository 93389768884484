import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import {
  ResponseInterceptorProvider,
  AuthInterceptorProvider,
} from './core/interceptors';
import { DashboardEffects, TranslationEffects, reducers } from 'src/app/state';
import { environment } from '../environments/environment';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ToastService } from 'src/app/components/toast/services/toast.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ArrowIconModule } from 'src/app/components/arrow-icon/arrow-icon.module';  // Import the ArrowComponent
import { AppComponent } from './app.component';
import { NavModule } from './layout/nav/nav.module';
import { PlaylistEffects } from './state/playlist';
import { VideoAnalysisSkeletonComponent } from './skeletons/video-analysis-skeleton.component';
import {  FieldmapSkeletonComponent } from './skeletons/fieldmap-skeleton.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DashboardListSkeletonComponent } from './skeletons/dashboard-list-skeleton.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([DashboardEffects, TranslationEffects, PlaylistEffects]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ArrowIconModule,
    NavModule,
    VideoAnalysisSkeletonComponent,
    FieldmapSkeletonComponent,
    DashboardListSkeletonComponent
  ],
  providers: [
    ResponseInterceptorProvider,
    AuthInterceptorProvider,
    TranslateService,
    ToastService,
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
