import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, EmptyStateModule } from '@sportiananalysis/ui';
import { CollapsibleModule } from 'src/app/components/collapsible/collapsible.module';
import { TabsModule } from 'src/app/components/tabs/tabs.module';

@Component({
  selector: 'app-notification-panel',
  standalone: true,
  imports: [
    CollapsibleModule,
    ButtonModule,
    TranslateModule,
    TabsModule,
    TranslateModule,
    EmptyStateModule,
  ],
  templateUrl: './notification-panel.component.html',
  styleUrl: './notification-panel.component.scss',
})
export class NotificationPanelComponent implements OnInit {
  @Input() isPanelExpanded!: boolean;
  @Output() closePanel = new EventEmitter<void>();
  isExpanded: boolean = true;
  days: number = 30;
  downloads!: any[];
  downloadUnreadCount: number = 0;

  ngOnInit(): void {
    this.downloads = [
      {
        type: 'preparing',
        unread: true,
      },
      {
        type: 'success',
      },
      {
        type: 'error',
      },
      {
        type: 'expired',
      },
    ];

    this.downloadUnreadCount = this.downloads.filter(
      (download) => download.unread
    ).length;
  }

  onPanelClicked(event: any) {
    if (!event.expanded) {
      this.closePanel.emit();
    }
  }

  onAccept() {
    this.closePanel.emit();
  }
}
