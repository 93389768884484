import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from './constants';
import { Subject } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';
import oidcClient from 'oidc-client';
import { environment } from '@environments/environment';

const { UserManager } = oidcClient;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  supportedLanguages = SUPPORTED_LANGUAGES.map(lang => lang.key);
  private _unsubscribeAll$ = new Subject<void>();
  private userManager: any;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang(this.translateService.currentLang || 'en');
    this.userManager = new UserManager(environment.idsConfig);
    
    this.authService.getUser().then(user => {
      if (user) {
        this.authService.setToken(user.access_token);
      } else {
        this.authService.handleLogout();
      }
    });
  }

  async ngOnInit() {
    const user = await this.userManager.getUser();
    if (!user) {
      this.authService.handleLogout();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }
}