<div class="navbar">
  <div class="navbar-left">
    <!-- Logo and Navigation Link -->
    <div class="logo">
      <img
        class="dashboard-form-close"
        src="../../../../assets/images/icons/iso.svg"
        alt="Analysis Icon"
      />
      <a routerLink="/" class="nav-logo-link">Analysis</a>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a [routerLink]="['/dashboards']" routerLinkActive="active"  class="nav-link">{{ 'texts.dashboards' | translate }}</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/playlists']" routerLinkActive="active"  class="nav-link">{{ 'texts.playlists' | translate }}</a>
      </li>
    </ul>
  </div>

  <div class="navbar-right">
    <!-- TODO: Help Icon -->
    <!-- <div class="icon-container" title="Help">
      <i class="fa fa-question-circle"></i>
    </div> -->

    <!--  TODO: Notification Icon -->
    <!-- <div class="icon-container" title="Notifications">
      <i class="fa fa-bell"></i>
    </div> -->

    <!--  TODO: Apps Icon -->
    <!-- <div class="icon-container" title="Apps">
      <i class="fa fa-th"></i>
      <span>Apps</span>
    </div> -->

    <!-- User Information -->
    <div class="user-info" [spauiMenuTriggerFor]="$any(menu?.menu)">
      <div class="user-initials">{{initials}}</div>
      <span>{{fullName}}</span>
      <button class="menu-button">
        <img
        class="menu-button__img"
        src="../../../../assets/images/icons/arrow_drop_down.svg"
        alt="Dropdown Icon"
      />
      </button>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<spaui-menu
  *ngIf="menuItems"
  [items]="menuItems"
  (menuClick)="onAction($event)"
/>
