import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES } from './constants';
import { Subject } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  supportedLanguages = SUPPORTED_LANGUAGES.map(lang => lang.key);;

  private _unsubscribeAll$!: Subject<void>;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService) {
    this._unsubscribeAll$ = new Subject<void>();

    this.translateService.addLangs(this.supportedLanguages);
    const lang = localStorage.getItem('language') || 'en'
    this.translateService.setDefaultLang(lang);

    // TODO: Check if this is required
    // const browserLang = this.translateService.getBrowserLang() || 'en';

    // this.store.dispatch(changeLanguage({ language: browserLang }));

    // this.store
    //   .select(selectLanguage)
    //   .pipe(takeUntil(this._unsubscribeAll$))
    //   .subscribe({
    //     next: (language) => {
    //       this.onChangeLanguage(language);
    //     },
    //   });
  
      this.authService.getUser().then(user => {
        this.authService.setToken(user.access_token)
      });
  }

  // TODO: Check if this is required
  // onChangeLanguage(language: string) {
  //   language = this.supportedLanguages.includes(language) ? language : 'en';
  //   this.translateService.use(language);
  // }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }
}
