import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
const routes: Routes = [
  {
    path: 'dashboards',
    loadChildren: () =>
      import('./modules/dashboards/dashboards.module').then(
        (m) => m.DashboardsModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'playlists',
    loadChildren: () =>
      import('./modules/playlists/playlists.module').then(
        (m) => m.PlaylistsModule
      ),
    canActivate: [authGuard],
  },
  { path: '**', redirectTo: '/dashboards' },
  { path: '', redirectTo: '/dashboards', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
