import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';
import { DialogModule } from 'src/app/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ChangePasswordDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule,
  ],
  exports: [ChangePasswordDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChangePasswordDialogModule {}
