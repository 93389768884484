import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileDialogComponent } from './user-profile-dialog.component';
import { ButtonModule } from '@sportiananalysis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'src/app/components/dialog/dialog.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { ToastModule } from "src/app/components/toast/toast.module";



@NgModule({
  declarations: [
    UserProfileDialogComponent
  ],
  imports: [
    ButtonModule,
    TranslateModule,
    DialogModule,
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    MaterialModule,
    ToastModule
  ],
  exports: [UserProfileDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserProfileDialogModule { }
