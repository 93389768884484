/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
  HttpEvent,
} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body && event.body.result) {
          return event.clone({ body: event.body.result });
        }

        return event;
      }),
    );
  }
}

export const ResponseInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: ResponseInterceptor,
};
